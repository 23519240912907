<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">推荐小程序</div>
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 800px;">
				<div>
					<a-button type="primary" icon="plus" @click="addMiniappAct()">添加小程序</a-button>
				</div>
				
				<div class="mt30">
					<div class="wxb-table-gray">
						<a-table rowKey="recommend_app_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							<div class="flex center" slot="logo" slot-scope="logo,record">
								<img v-if="logo!=null" :src="logo" class="miniapp-recommend-logo" />
							</div>
					
					
							<template slot="action" slot-scope="record,index">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editMiniappAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											</a-menu-item>
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="del(record)">
													<i class="iconfont ft14 icondelete"></i>
													<span class="ml10">删除</span>
												</a>
											</a-menu-item>
					
										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>
	
		<div v-if="editMiniappLoading">
			<edit-miniapp :recommend_app_id="recommend_app_id" :visible="editMiniappLoading" @cancel="cancelEditMiniapp" @ok="okEditMiniapp"></edit-miniapp>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import editMiniapp from './components/recommend/modal/editMiniapp.vue';
	export default{
		mixins: [listMixin],
		components:{
			editMiniapp,
		},
		data(){
			return{
				loading: false,
				editMiniappLoading:false,
				recommend_app_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '小程序logo',dataIndex: 'logo',align: 'center',scopedSlots: {customRender: 'logo'}},
					{title: '小程序名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '小程序ID ',dataIndex: 'app_id',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods:{
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getRecommendAppList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			del(record){
				this.$confirm({
					title: '确定删除这个推荐小程序吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delRecommendApp', {
								recommend_app_id:record.recommend_app_id
							}).then(res => {
								this.$message.success('删除成功',1,()=>{
									this.getLists();
								})
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					},
				});
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			addMiniappAct(){
				this.recommend_app_id=0;
				this.editMiniappLoading=true;
			},
			editMiniappAct(record){
				this.recommend_app_id=record.recommend_app_id;
				this.editMiniappLoading=true;
			},
			cancelEditMiniapp(){
				this.editMiniappLoading=false;
			},
			okEditMiniapp(){
				this.editMiniappLoading=false;
				this.getLists();
			},
		},
	}
</script>

<style>
	.miniapp-recommend-logo{
		width: 60px;
		height: 60px;
		border-radius: 4px;
	}
</style>
