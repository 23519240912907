<template>
	<div>
		<a-modal title="推荐小程序" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					
					<div>
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">上传logo</div>
							<div class="ft12 ftw400 cl-notice ml8">建议上传100x100px</div>
						</div>
						
						<div class="mt12">
							<div class="flex center">
								<upload-img v-model="form.logo"></upload-img>
							</div>
						</div>
					</div>
					
					<a-input class="edit-miniapp-input mt24" style="width: 420px;" prefix="小程序名称" placeholder="请输入小程序名称" v-model="form.name" :maxLength="20">
					</a-input>
					<a-input class="edit-miniapp-input mt24" prefix="小程序ID" style="width: 420px;" placeholder="请输入小程序ID" v-model="form.app_id" :maxLength="20">
					</a-input>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import uploadImg from '../../../../../components/upload/upload.vue';
	export default {
		components:{
			uploadImg,
		},
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			recommend_app_id:{
				type:Number,
				default:0
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form:{
					name:'',
					app_id:'',
					logo:null,
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showRecommendApp',{
					recommend_app_id:this.recommend_app_id,
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveRecommendApp',{
					recommend_app_id:this.recommend_app_id,
					name:this.form.name,
					logo:this.form.logo,
					app_id:this.form.app_id,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.edit-miniapp-input.ant-input-group-wrapper .ant-input,.edit-miniapp-input.ant-input-affix-wrapper .ant-input{
		padding-left: 100px;
	}
	.edit-miniapp-input.ant-input-group-wrapper .ant-input,.edit-miniapp-input.ant-input-affix-wrapper .ant-input-prefix{
		color:#656A75;
	}
</style>
